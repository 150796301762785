import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, useTheme, useMediaQuery, Tooltip } from '@material-ui/core';
import { EmailHeading } from '../ContributionCommunication/components/ContributionCommunicationStyled';
import { Switch, StyledInput, StyledSlider } from '../../../components/switch/style';
import EditButton from '../../../components/UI/ListButtons/EditButton';
import ViewButton from '../../../components/UI/ListButtons/ViewButton';
import ChatIcon from './components/assets/Icons/Chat-icon.png';
import CommunitySvg from '../../../components/Icons/CommunityIcon';
import EnrolementSvg from '../../../components/Icons/EnrollmentIcon';
import SessionSvg from '../../../components/Icons/ReminderIcon';
import * as paidTier from 'selectors/paidTier';
import { MenuItem, OutlinedInput, Select, Paper } from '@material-ui/core';
import CrossIcon from '@material-ui/icons/Clear';
import { useState } from 'react';
import {
  getCommunicationTabData,
  updateEmailTemplate,
  disableAllEmails,
  disableEmailDoNotRemindMe,
  updateIsNewleyCreated,
} from 'services/communicationTab.service ';
import useContribution from '../hooks/useContribution';
import { useEffect } from 'react';
import Loader from 'components/UI/Loader';
import { useStyles } from '../ContributionCommunication/components/ContributionCommunicationStyled';
import styled from 'styled-components';
import { determineColorToUse, determineDarkThemedColorToUse, getThemedColors } from 'services/contributions.service';
import './ContributionCommunicationPanel.scss';
import classesNames from './ContributionCommunicationPanel.scss';
import { TOOLTIP } from '../../../../src/constants';
import { colors } from 'utils/styles';
import InfoIcon from '@material-ui/icons/Info';
import { useAccount, useShallowEqualSelector } from 'hooks';
import { UserRoles } from 'helpers/constants';
import Modal from 'components/UI/Modal';
import CoachImage from 'assets/chatlogo.png';
import Joyride, { EVENTS } from 'react-joyride';
import Button from 'components/FormUI/Button';
import Checkbox from '@material-ui/core/Checkbox';
import { lightOrDark } from 'utils/utils';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FeatureFeedbackModal from 'components/Modals/FeatureFeedbackModal';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCohealerContribution, setIsNewlyCreated } from 'actions/contributions';
import useEmailMarketing from 'pages/CreateCampaign/Hooks/useEmailMarketing';
import axiosInstance from 'utils/axiosInstance';
import { Spinner } from 'react-activity';
import { notifyPlanUpgrade } from 'services/community.service';
const StyledSelect = styled(Select)`
  width: calc(100% - 33px);
  color: ${props => (props.disabled ? '#9b9b9b !important' : props.labelColor)};
  label {
    color: ${props => (props.disabled ? '#9b9b9b !important' : props.labelColor)};
  }

  svg {
    color: ${props => props.labelColor};

    path {
      fill: ${props => props.labelColor} !important;
    }
  }
`;
const StyledCloseIcon = styled(CrossIcon)`
  margin: 5px;
  height: 15px;
  width: 15px;
  &:hover {
    cursor: pointer;
  }
`;
const StyledImg = styled.div`
  background-image: url(${({ previewImg }) => previewImg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: inline-block;
  border-radius: 40px;
  align-items: center;
  z-index: 1;
`;

const StyledGrid = styled(Grid)`
  margin: 10px 0px;
`;

const StyledEditButton = styled(EditButton)`
  padding: 0px;
`;

const StyledImage = styled.img`
  height: 60%;
  width: 2%;

  ${({ mobileView }) =>
    mobileView &&
    `
    height: 60%;
  width:10%;
  `}
`;
const StyledInfoIcon = styled(InfoIcon)`
  margin-left: 20px;
`;

const EnableContainerHeading = styled.div`
  color: #215c73;
  font-family: Avenir;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 20px;
  text-align: start;
  margin-bottom: 10px;
`;

const EnableContainerText = styled.div`
  color: ${({ isDarkThemeEnabled }) => (isDarkThemeEnabled ? 'white' : '#000')};
  font-family: Avenir;
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  line-height: 20px;
  text-align: start;
`;

const EnableOutsideContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`;
const StyledSendingAddressSelect = styled(StyledSelect)`
  label {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: ${props => (props.disabled ? '#9b9b9b !important' : '')};
  }

  .MuiSelect-select {
    font-family: Avenir;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: ${props => (props.disabled ? '#9b9b9b' : props.labelColor)};
  }

  .MuiPopover-paper {
    top: 140px;
  }
`;
const NewTag = styled.span`
  padding: 2px 6px;
  background-color: #215c73;
  border-radius: 4px;
  color: white;
  font-family: Avenir;
  font-style: italic;
  font-size: 11px;
  font-weight: 800;
  text-align: center;
  margin: 1px 10px 0px 0px;
  height: 20px;
  cursor: pointer;

  &:hover {
    text-decoration: none !important;
  }
`;
const sentEmailText = 'If you disable this email, your clients will no longer receive this email';
const useBrandingText =
  'On Choosing custom your emails will be branded as per your customized service colors and logo.';

export default function ContributionCommunicationPanel({ click, getApiCall }) {
  const [customTemData, setcustomTemData] = useState([]);
  const { verifiedSendingAddresses } = useEmailMarketing();
  const contribution = useContribution();
  const [showInterestModal, setShowInterestModal] = useState(false);
  const [showInterestFirstModal, setShowInterestFirstModal] = useState(false);
  const [emailEnableDisableStatus, setEmailEnableDisableStatus] = useState(null);
  const [sendingAddressState, setSendingAddressState] = useState(
    contribution?.sendNotificationsEmailFrom || `noreply@cohere.live`,
  );
  const activePaidTierPlan = useShallowEqualSelector(paidTier.activePaidTierPlanSelector);
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [showSendingEmailModal, setShowSendingEmailModal] = useState(false);
  const [initialMode, setinitialMode] = useState(false);
  const [domainConnected, setdomainConnected] = useState(verifiedSendingAddresses.length > 0 ? true : false);
  const [accordionOpen, setAccordionOpen] = useState(true);
  const [accordionOpen1, setAccordionOpen1] = useState(false);
  const [accordionOpen2, setAccordionOpen2] = useState(false);
  const [accordionOpen3, setAccordionOpen3] = useState(false);
  const [accordionOpen4, setAccordionOpen4] = useState(false);
  const theme = useTheme();
  const dispatch = useDispatch();

  const isNewlyCreated = useSelector(state => state.contributions?.isNewlyCreated);
  let colorToUse = determineColorToUse(contribution);
  const { id: contributionId } = contribution;
  const { themedColor, themedCardBackgroundColor } = getThemedColors(contribution);
  const isDarkThemeEnabled = determineDarkThemedColorToUse(contribution);
  const NotificationSettingTextColor = isDarkThemeEnabled ? colorToUse?.AccentColorCode : '#215C73';
  const NotificationSettingBgColor = isDarkThemeEnabled ? '#3D4246' : '#F1F8FF';
  const styledProps = {
    backgroundColor: themedCardBackgroundColor,
    color: themedColor,
  };

  const classes = useStyles(styledProps);

  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const [loader, setLoader] = useState(false);
  const getCommunicationData = () => {
    getCommunicationTabData(contributionId)
      .then(res => {
        setLoader(false);
        setcustomTemData(res?.customTemplates);
      })
      .catch(err => {
        setLoader(false);
      });
  };
  useEffect(() => {
    getCommunicationData();
  }, [contributionId, getApiCall, accordionOpen, accordionOpen1, accordionOpen2, accordionOpen3, accordionOpen4]);

  const [isFeedbackPopupOpen, setIsFeedbackOpen] = useState(false);

  const handleCloseFeedback = () => {
    setIsFeedbackOpen(false);
    dispatch(setIsNewlyCreated(false));
  };

  const handleFinishFeedbackCallback = () => {
    dispatch(setIsNewlyCreated(false));
    setEmailAutomations(true);
  };

  useEffect(() => {
    if (isNewlyCreated) {
      if (contribution?.showRatingFeedbackPopUp) {
        setIsFeedbackOpen(true);
      } else {
        setEmailAutomations(true);
      }
      updateIsNewleyCreated({ id: contributionId, type: 'Contribution' });
    }
  }, [contribution, contributionId]);

  const EnrollmentSales = customTemData.filter(el => el.category === 'Enrollment and Sales');

  const EmailNotification = customTemData.filter(el => el.category === 'My Email Notification');
  const SessionContent = customTemData.filter(el => el.category === 'Sessions and Content');
  const Community = customTemData.filter(el => el.category === 'Community');
  const Chat = customTemData.filter(el => el.category === 'Chat');
  const [emailTem, setemailTem] = useState([true, false]);
  const switchFun = (item, e) => {
    setLoader(true);
    const emailValue = e.target.checked.toString();
    item.sendEmailAllowed = emailValue;
    item['contributionId'] = contributionId;
    updateEmailTemplate(item)
      .then(res => {
        getCommunicationData();
      })
      .catch(err => {});
  };

  const switchFunCustomBranding = (item, e) => {
    setLoader(true);
    item.isCustomBrandingColorsEnabled = e.target.checked;
    item['contributionId'] = contributionId;
    updateEmailTemplate(item)
      .then(res => {
        getCommunicationData();
      })
      .catch(err => {});
  };

  function rightRotateByOne(arr) {
    if (!arr || arr.length === 0) {
      return arr;
    } else {
      const lastElement = arr.pop();
      arr.unshift(lastElement);
      return arr;
    }
  }
  const [loading, setLoading] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const { currentRole } = useAccount();
  const isCoach = currentRole === UserRoles.cohealer;
  const [emailAutomations, setEmailAutomations] = useState(false);
  const [automationsloader, setAutomationsLoader] = useState(false);
  const [run, setRun] = useState(false);
  const categories = ['Enrollment and Sales', 'My Email Notification', 'Sessions and Content', 'Community'];
  const emailValue = false;
  const textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';

  const [selected, setSelected] = useState(false);

  const [steps, setSteps] = useState([]);

  let joyRideHelpers;
  const getHelpers = helpers => {
    joyRideHelpers = helpers;
  };

  const handleJoyrideCallback = data => {
    const { status, type, index } = data;
    const nextStepIndex = index + 1;

    const finishedStatuses = ['finished', 'skipped'];
    if (finishedStatuses.includes(status)) {
      setRun(false);
    }
    if ([EVENTS.STEP_AFTER].includes(type)) {
      setStepIndex(nextStepIndex);
    }
    if (data.action === 'close') {
      setRun(false);
    }

    console.groupCollapsed(type);
    console.groupEnd();
  };

  const onEmailAutomationsSubmit = () => {
    dispatch(setIsNewlyCreated(false));
    setEmailAutomations(false);
    if (contribution.dontRemindMeCustomEmailPopup === false) {
      setRun(true);
    }
  };

  const doNotRemindMe = checked => {
    setSelected(checked);
    disableEmailDoNotRemindMe(contributionId);
    setTimeout(() => {
      setRun(false);
    }, 500);
  };

  const clearSendEmail = () => {
    setLoading(true);
    axiosInstance
      .post(`/Contribution/UpdateNotificationSendFrom/${contributionId}/noreply@cohere.live`)
      .then(() => {
        setLoading(false);
        dispatch(fetchCohealerContribution(contributionId));
        setSendingAddressState('noreply@cohere.live');
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setSteps([
      {
        disableBeacon: true,
        hideFooter: true,
        content: (
          <div
            className="step1"
            style={{ paddingLeft: '15px', paddingRight: '15px', backgroundColor: isDarkThemeEnabled ? '#2d2f31' : '' }}
          >
            <div
              className="image"
              style={{
                height: '70px',
                width: '70px',
                backgroundColor: 'white',
                position: 'absolute',
                borderRadius: '100px',
                top: -45,
                borderColor: '#215C73',
                borderWidth: '5px',
              }}
            >
              <StyledImg previewImg={CoachImage} />
            </div>
            <EnableContainerHeading>Enable & Customize Client Notifications</EnableContainerHeading>
            <EnableContainerText isDarkThemeEnabled={isDarkThemeEnabled}>
              Cohere's automated client notifications streamline communication and enhance engagement. These emails
              cover everything from enrollment and session reminders to community posts and more. Toggle the switch to
              enable them and tailor content to match your coaching style and your clients' preferences.
            </EnableContainerText>
            <EnableOutsideContainer>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <FormControlLabel
                  style={{ color: isDarkThemeEnabled ? 'white' : '' }}
                  control={
                    <Checkbox
                      checked={selected}
                      onChange={({ target: { checked } }) => doNotRemindMe(checked)}
                      color="primary"
                    />
                  }
                  label="Don’t remind me again"
                />
              </div>
              <Button
                backgroundColor={colorToUse?.PrimaryColorCode}
                textColor={textColor}
                autoWidth
                style={{ marginTop: '10px', height: '29px', padding: '7px 16px' }}
                onClick={() => {
                  setRun(false);
                }}
              >
                Got It
              </Button>
            </EnableOutsideContainer>
          </div>
        ),
        placement: 'top',
        target: '#panel1bh-header-container',
      },
    ]);
  }, [selected]);

  const onEmailAutomationsCancel = () => {
    setAutomationsLoader(true);
    disableAllEmails(contributionId).then(res => {
      setAutomationsLoader(false);
      dispatch(setIsNewlyCreated(false));
      setEmailAutomations(false);
      getCommunicationData();
    });
  };
  const isSelectedEmailDefault = verifiedSendingAddresses.find(
    item => item.email === sendingAddressState && item.isDefault === true,
  );

  console.log('activePaidTierPlan?.isEmailCampaignEnabled', activePaidTierPlan);
  return (
    <>
      {!mobileView && (
        <>
          <Grid
            container
            style={{
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: '30px',
              background: isDarkThemeEnabled == false ? '#F5F5F5' : themedCardBackgroundColor,
            }}
          >
            <div className={isDarkThemeEnabled ? classes.PannelTopBarTopDarkEN : classes.PannelTopBarTopEN}>
              Email Notifications
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '10px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {loading === false ? (
                sendingAddressState != 'noreply@cohere.live' &&
                sendingAddressState != null && (
                  <div
                    style={{
                      backgroundColor: '#FFFFFF',
                      color: '#215C73',
                      border: '1px solid #215C73',
                      padding: '16px, 10px, 16px, 10px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '40px',
                      borderRadius: '4px',
                      paddingInline: '10px',
                      margin: '10px',
                      fontWeight: '900',
                    }}
                  >
                    {sendingAddressState}
                    <span>
                      {' '}
                      <StyledCloseIcon
                        onClick={() => {
                          setSendingAddressState('noreply@cohere.live');
                          clearSendEmail();
                        }}
                        fontSize="8px"
                        style={{ color: 'black' }}
                      />
                    </span>
                  </div>
                )
              ) : (
                <Spinner color="#116582" size={20} />
              )}
              {activePaidTierPlan?.isEmailCampaignEnabled === false ||
              activePaidTierPlan?.isEmailCampaignEnabled === undefined ? (
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    marginRight: '10px',
                    marginTop: '10px',
                    marginBottom: '10px',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    style={{ padding: '5px 20px', opacity: 0.4 }}
                    backgroundColor={colorToUse?.AccentColorCode}
                    textColor={colorToUse?.AccentColorCode}
                    borderColor={colorToUse?.AccentColorCode}
                    autoWidth
                    invert
                  >
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Tooltip
                        title="This feature is locked, please upgrade to Accelerate Plan."
                        arrow
                        enterTouchDelay={TOOLTIP.ENTER_DELAY}
                        leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                      >
                        <div>Send From Email</div>
                      </Tooltip>
                      <Tooltip
                        title="Use your personal email instead of noreply@cohere.live for notifications."
                        arrow
                        enterTouchDelay={TOOLTIP.ENTER_DELAY}
                        leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                      >
                        <InfoIcon
                          style={{ marginLeft: '10px', marginBottom: '3px' }}
                          htmlColor={colorToUse.PrimaryColorCode}
                        />
                      </Tooltip>
                    </div>
                  </Button>
                  <NewTag
                    onClick={() => {
                      setShowInterestFirstModal(true);
                    }}
                  >
                    Upgrade
                  </NewTag>
                </div>
              ) : verifiedSendingAddresses.length > 0 ? (
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    marginRight: '10px',
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                >
                  <Button
                    style={{ padding: '5px 10px' }}
                    onClick={() => {
                      setShowSendingEmailModal(true);
                    }}
                    backgroundColor={colorToUse?.AccentColorCode}
                    textColor={colorToUse?.AccentColorCode}
                    borderColor={colorToUse?.AccentColorCode}
                    autoWidth
                    invert
                  >
                    Send From Email
                    <Tooltip
                      title="Use your personal email instead of noreply@cohere.live for notifications."
                      arrow
                      enterTouchDelay={TOOLTIP.ENTER_DELAY}
                      leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                    >
                      <InfoIcon
                        style={{ marginLeft: '10px', marginBottom: '3px' }}
                        htmlColor={colorToUse.PrimaryColorCode}
                      />
                    </Tooltip>
                  </Button>
                </div>
              ) : (
                <></>
              )}
            </div>
          </Grid>
          <Grid container className={classes.PannelTopBar}>
            <Grid md={3}>
              <EmailHeading className={classes.EmailText}>Email Templates</EmailHeading>
            </Grid>
            <Grid md={1}>
              <EmailHeading className={classes.ActionsText}>Edit</EmailHeading>
            </Grid>
            <Grid md={4}>
              <EmailHeading>Email Subject</EmailHeading>
            </Grid>
            {/* <Grid md={3}>
              <EmailHeading style={{ textAlign: 'center' }}>Customize</EmailHeading>
            </Grid> */}
            <Grid md={2}>
              <EmailHeading>
                Send This Email
                <Tooltip title={sentEmailText} arrow enterTouchDelay={TOOLTIP.ENTER_DELAY}>
                  <StyledInfoIcon htmlColor={colorToUse?.PrimaryColorCode} style={{ marginRight: '10px' }} />
                </Tooltip>
              </EmailHeading>
            </Grid>
            <Grid md={2}>
              <EmailHeading>
                Use My Branding
                <Tooltip title={useBrandingText} arrow enterTouchDelay={TOOLTIP.ENTER_DELAY}>
                  <StyledInfoIcon htmlColor={colorToUse?.PrimaryColorCode} style={{ marginRight: '10px' }} />
                </Tooltip>
              </EmailHeading>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={12}>
              <div>
                <Accordion expanded={accordionOpen} id="panel1bh-header-container">
                  <AccordionSummary
                    style={{
                      backgroundColor: NotificationSettingBgColor,
                      width: '100%',
                      padding: '0 10px',
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    onClick={() => setAccordionOpen(!accordionOpen)}
                    className={isDarkThemeEnabled ? 'dark-notification-accordion' : ''}
                  >
                    <EnrolementSvg color={NotificationSettingTextColor} height="60%" width="2%" />
                    {/* <img src={EnrolementIcon} right="5" /> */}
                    <Typography
                      sx={{
                        marginTop: '5px',
                        paddingLeft: '10px',
                        fontSize: '16px',
                        fontWeight: '850',
                        fontStyle: 'normal',
                        color: NotificationSettingTextColor,
                        fontFamily: 'Avenir',
                      }}
                      className={classes.TabsTyphography}
                    >
                      Enrollment and Sales
                    </Typography>
                    <Typography sx={{ color: 'text.secondary' }}></Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    style={{
                      padding: '30px 16px 16px',
                      backgroundColor: themedCardBackgroundColor,
                      color: themedColor,
                    }}
                  >
                    {rightRotateByOne(EnrollmentSales)?.map((item, index) => {
                      const {
                        category,
                        emailSubject,
                        emailText,
                        emailType,
                        isEmailEnabled,
                        sendEmailAllowed,
                        name,
                        isCustomBrandingColorsEnabled,
                        currentColors,
                      } = item;
                      return (
                        <StyledGrid container>
                          <Grid md={3}>
                            <p
                              onClick={() => {
                                click(item);
                              }}
                              style={{ cursor: 'pointer' }}
                            >
                              {name}
                            </p>
                          </Grid>
                          <Grid md={1}>
                            <div className={classes.ButtonViewHide}>
                              <StyledEditButton
                                handleOnClick={() => {
                                  click(item);
                                }}
                                color={isDarkThemeEnabled ? colorToUse?.PrimaryColorCode : ''}
                              />
                            </div>
                          </Grid>
                          <Grid md={4}>
                            <p>{emailSubject}</p>
                          </Grid>
                          {/* <Grid md={2}>
                            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                              <p className={classes.ButtonViewHide}> {isEmailEnabled ? 'Custom' : 'Default'}</p>
                              <Switch className="switch" style={{ marginLeft: '6px' }}>
                                <StyledInput
                                  defaultChecked={isEmailEnabled}
                                  value={isEmailEnabled}
                                  type="checkbox"
                                  onChange={e => {
                                    switchFun(item, e);
                                  }}
                                  checked={isEmailEnabled}
                                />
                                <StyledSlider className="slider round" />
                              </Switch>
                            </div>
                          </Grid> */}
                          <Grid md={2}>
                            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                              <p className={classes.ButtonViewHide}>
                                {' '}
                                {sendEmailAllowed === null
                                  ? 'Enable'
                                  : sendEmailAllowed === 'true'
                                  ? 'Enable'
                                  : 'Disable'}
                              </p>
                              <Switch className="switch" style={{ marginLeft: '6px' }}>
                                <StyledInput
                                  defaultChecked={
                                    sendEmailAllowed === null ? true : sendEmailAllowed == 'true' ? true : false
                                  }
                                  value={sendEmailAllowed === null ? true : sendEmailAllowed == 'true' ? true : false}
                                  type="checkbox"
                                  onChange={e => {
                                    switchFun(item, e);
                                  }}
                                  checked={sendEmailAllowed === null ? true : sendEmailAllowed == 'true' ? true : false}
                                />
                                <StyledSlider
                                  style={
                                    isDarkThemeEnabled
                                      ? {
                                          backgroundColor:
                                            sendEmailAllowed === null
                                              ? colorToUse?.PrimaryColorCode
                                              : sendEmailAllowed == 'true'
                                              ? colorToUse?.PrimaryColorCode
                                              : '#ccc',
                                        }
                                      : {}
                                  }
                                  className="slider round"
                                />
                              </Switch>
                            </div>
                          </Grid>
                          <Grid md={2}>
                            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                              <p className={classes.ButtonViewHide}>
                                {' '}
                                {isCustomBrandingColorsEnabled ? 'Enable' : 'Disable'}
                              </p>
                              <Switch className="switch" style={{ marginLeft: '6px' }}>
                                <StyledInput
                                  defaultChecked={isCustomBrandingColorsEnabled}
                                  value={isCustomBrandingColorsEnabled}
                                  type="checkbox"
                                  onChange={e => {
                                    switchFunCustomBranding(item, e);
                                  }}
                                  checked={isCustomBrandingColorsEnabled}
                                />
                                <StyledSlider
                                  style={
                                    isDarkThemeEnabled
                                      ? {
                                          backgroundColor: isCustomBrandingColorsEnabled
                                            ? colorToUse?.PrimaryColorCode
                                            : '#ccc',
                                        }
                                      : {}
                                  }
                                  className="slider round"
                                />
                              </Switch>
                            </div>
                          </Grid>
                        </StyledGrid>
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={accordionOpen1}>
                  <AccordionSummary
                    style={{
                      backgroundColor: NotificationSettingBgColor,
                      width: '100%',
                      padding: '0 10px',
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    onClick={() => setAccordionOpen1(!accordionOpen1)}
                    className={isDarkThemeEnabled ? 'dark-notification-accordion' : ''}
                  >
                    <SessionSvg color={NotificationSettingTextColor} height="60%" width="2%" />

                    <Typography
                      sx={{
                        marginTop: '5px',
                        width: '33%',
                        flexShrink: 0,
                        paddingLeft: '10px',
                        fontSize: '16px',
                        fontWeight: '850',
                        fontStyle: 'normal',
                        color: NotificationSettingTextColor,
                        fontFamily: 'Avenir',
                      }}
                    >
                      Session Reminders and Self-Paced Content
                    </Typography>
                    <Typography sx={{ color: 'text.secondary' }}></Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.AccordianDetails}>
                    {SessionContent.map((item, index) => {
                      const {
                        category,
                        emailSubject,
                        emailText,
                        emailType,
                        isEmailEnabled,
                        sendEmailAllowed,
                        name,
                        isCustomBrandingColorsEnabled,
                        currentColors,
                      } = item;
                      return (
                        <StyledGrid container>
                          <Grid md={3}>
                            <p
                              onClick={() => {
                                click(item);
                              }}
                              style={{ cursor: 'pointer' }}
                            >
                              {name}
                            </p>
                          </Grid>
                          <Grid md={1}>
                            <div className={classes.ButtonViewHide}>
                              <StyledEditButton
                                handleOnClick={() => {
                                  click(item);
                                }}
                                color={isDarkThemeEnabled ? colorToUse?.PrimaryColorCode : ''}
                              />
                            </div>
                          </Grid>
                          <Grid md={4}>
                            <p>{emailSubject}</p>
                          </Grid>
                          <Grid md={2}>
                            <div style={{ display: 'flex' }}>
                              <p className={classes.ButtonEnb}>
                                {sendEmailAllowed === null
                                  ? 'Enable'
                                  : sendEmailAllowed === 'true'
                                  ? 'Enable'
                                  : 'Disable'}
                              </p>
                              <Switch className="switch" style={{ marginLeft: '6px' }}>
                                <StyledInput
                                  defaultChecked={
                                    sendEmailAllowed === null ? true : sendEmailAllowed == 'true' ? true : false
                                  }
                                  value={sendEmailAllowed === null ? true : sendEmailAllowed == 'true' ? true : false}
                                  type="checkbox"
                                  onChange={e => {
                                    switchFun(item, e);
                                  }}
                                  checked={sendEmailAllowed === null ? true : sendEmailAllowed == 'true' ? true : false}
                                />
                                <StyledSlider
                                  style={
                                    isDarkThemeEnabled
                                      ? {
                                          backgroundColor:
                                            sendEmailAllowed === null
                                              ? colorToUse?.PrimaryColorCode
                                              : sendEmailAllowed == 'true'
                                              ? colorToUse?.PrimaryColorCode
                                              : sendEmailAllowed == true
                                              ? colorToUse?.PrimaryColorCode
                                              : '#ccc',
                                        }
                                      : {}
                                  }
                                  className="slider round"
                                />
                              </Switch>
                            </div>
                          </Grid>
                          <Grid md={2}>
                            <div style={{ display: 'flex' }}>
                              <p className={classes.ButtonEnb}>
                                {isCustomBrandingColorsEnabled ? 'Enable' : 'Disable'}
                              </p>
                              <Switch className="switch" style={{ marginLeft: '6px' }}>
                                <StyledInput
                                  defaultChecked={isCustomBrandingColorsEnabled}
                                  value={isCustomBrandingColorsEnabled}
                                  type="checkbox"
                                  onChange={e => {
                                    switchFunCustomBranding(item, e);
                                  }}
                                  checked={isCustomBrandingColorsEnabled}
                                />
                                <StyledSlider
                                  style={
                                    isDarkThemeEnabled
                                      ? {
                                          backgroundColor: isCustomBrandingColorsEnabled
                                            ? colorToUse?.PrimaryColorCode
                                            : '#ccc',
                                        }
                                      : {}
                                  }
                                  className="slider round"
                                />
                              </Switch>
                            </div>
                          </Grid>
                        </StyledGrid>
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={accordionOpen2}>
                  <AccordionSummary
                    style={{
                      backgroundColor: NotificationSettingBgColor,
                      width: '100%',
                      padding: '0 10px',
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    onClick={() => setAccordionOpen2(!accordionOpen2)}
                    className={isDarkThemeEnabled ? 'dark-notification-accordion' : ''}
                  >
                    <CommunitySvg color={NotificationSettingTextColor} height="60%" width="2%" />
                    <Typography
                      sx={{
                        width: '33%',
                        flexShrink: 0,
                        paddingLeft: '10px',
                        fontSize: '16px',
                        fontWeight: '850',
                        fontStyle: 'normal',
                        color: NotificationSettingTextColor,
                        fontFamily: 'Avenir',
                      }}
                    >
                      Community, Posts and Comments
                    </Typography>
                    <Typography sx={{ color: 'text.secondary' }}></Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.AccordianDetails}>
                    {Community.map((item, index) => {
                      const {
                        category,
                        emailSubject,
                        emailText,
                        emailType,
                        isEmailEnabled,
                        sendEmailAllowed,
                        name,
                        isCustomBrandingColorsEnabled,
                        currentColors,
                      } = item;
                      return (
                        <StyledGrid container>
                          <Grid md={3}>
                            <p
                              onClick={() => {
                                click(item);
                              }}
                              style={{ cursor: 'pointer' }}
                            >
                              {name}
                            </p>
                          </Grid>
                          <Grid md={1}>
                            <div className={classes.ButtonViewHide}>
                              <StyledEditButton
                                handleOnClick={() => {
                                  click(item);
                                }}
                                color={isDarkThemeEnabled ? colorToUse?.PrimaryColorCode : ''}
                              />
                            </div>
                          </Grid>
                          <Grid md={4}>
                            <p>{emailSubject}</p>
                          </Grid>
                          <Grid md={2}>
                            <div style={{ display: 'flex' }}>
                              <p className={classes.ButtonEnb}>
                                {sendEmailAllowed === null
                                  ? 'Enable'
                                  : sendEmailAllowed === 'true'
                                  ? 'Enable'
                                  : 'Disable'}
                              </p>
                              <Switch className="switch" style={{ marginLeft: '6px' }}>
                                <StyledInput
                                  defaultChecked={
                                    sendEmailAllowed === null ? true : sendEmailAllowed == 'true' ? true : false
                                  }
                                  value={sendEmailAllowed === null ? true : sendEmailAllowed == 'true' ? true : false}
                                  type="checkbox"
                                  onChange={e => {
                                    switchFun(item, e);
                                  }}
                                  checked={sendEmailAllowed === null ? true : sendEmailAllowed == 'true' ? true : false}
                                />
                                <StyledSlider
                                  style={
                                    isDarkThemeEnabled
                                      ? {
                                          backgroundColor:
                                            sendEmailAllowed === null
                                              ? colorToUse?.PrimaryColorCode
                                              : sendEmailAllowed == 'true'
                                              ? colorToUse?.PrimaryColorCode
                                              : '#ccc',
                                        }
                                      : {}
                                  }
                                  className="slider round"
                                />
                              </Switch>
                            </div>
                          </Grid>
                          <Grid md={2}>
                            <div style={{ display: 'flex' }}>
                              <p className={classes.ButtonEnb}>
                                {isCustomBrandingColorsEnabled ? 'Enable' : 'Disable'}
                              </p>
                              <Switch className="switch" style={{ marginLeft: '6px' }}>
                                <StyledInput
                                  defaultChecked={isCustomBrandingColorsEnabled}
                                  value={isCustomBrandingColorsEnabled}
                                  type="checkbox"
                                  onChange={e => {
                                    switchFunCustomBranding(item, e);
                                  }}
                                  checked={isCustomBrandingColorsEnabled}
                                />
                                <StyledSlider
                                  style={
                                    isDarkThemeEnabled
                                      ? {
                                          backgroundColor: isCustomBrandingColorsEnabled
                                            ? colorToUse?.PrimaryColorCode
                                            : '#ccc',
                                        }
                                      : {}
                                  }
                                  className="slider round"
                                />
                              </Switch>
                            </div>
                          </Grid>
                        </StyledGrid>
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={accordionOpen4}>
                  <AccordionSummary
                    style={{
                      backgroundColor: NotificationSettingBgColor,
                      width: '100%',
                      padding: '0 10px',
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    onClick={() => setAccordionOpen4(!accordionOpen4)}
                    className={isDarkThemeEnabled ? 'dark-notification-accordion' : ''}
                  >
                    <EnrolementSvg color={NotificationSettingTextColor} height="60%" width="2%" />
                    {/* <img src={EnrolementIcon} right="5" /> */}
                    <Typography
                      sx={{
                        width: '33%',
                        flexShrink: 0,
                        paddingLeft: '10px',
                        fontSize: '16px',
                        fontWeight: '850',
                        fontStyle: 'normal',
                        color: NotificationSettingTextColor,
                        fontFamily: 'Avenir',
                        marginTop: '5px',
                      }}
                      className={classes.TabsTyphography}
                    >
                      My Email Notifications
                    </Typography>
                    <Typography sx={{ color: 'text.secondary' }}></Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    style={{
                      padding: '30px 16px 16px',
                      color: themedColor,
                      backgroundColor: themedCardBackgroundColor,
                    }}
                  >
                    {EmailNotification.map((item, index) => {
                      const {
                        category,
                        emailSubject,
                        emailText,
                        emailType,
                        isEmailEnabled,
                        sendEmailAllowed,
                        name,
                        isCustomBrandingColorsEnabled,
                        currentColors,
                      } = item;
                      return (
                        <StyledGrid container>
                          <Grid md={3}>
                            <p
                              onClick={() => {
                                click(item);
                              }}
                              style={{ cursor: 'pointer' }}
                            >
                              {name}
                            </p>
                          </Grid>
                          <Grid md={1}>
                            <div className={classes.ButtonViewHide}>
                              <StyledEditButton
                                handleOnClick={() => {
                                  click(item);
                                }}
                                color={isDarkThemeEnabled ? colorToUse?.PrimaryColorCode : ''}
                              />
                            </div>
                          </Grid>
                          <Grid md={4}>
                            <p>{emailSubject}</p>
                          </Grid>
                          <Grid md={2}>
                            <div style={{ display: 'flex' }}>
                              <p className={classes.ButtonViewHide}>
                                {' '}
                                {sendEmailAllowed === null
                                  ? 'Enable'
                                  : sendEmailAllowed === 'true'
                                  ? 'Enable'
                                  : 'Disable'}
                              </p>
                              <Switch className="switch" style={{ marginLeft: '6px' }}>
                                <StyledInput
                                  defaultChecked={
                                    sendEmailAllowed === null ? true : sendEmailAllowed == 'true' ? true : false
                                  }
                                  value={sendEmailAllowed === null ? true : sendEmailAllowed == 'true' ? true : false}
                                  type="checkbox"
                                  onChange={e => {
                                    switchFun(item, e);
                                  }}
                                  checked={sendEmailAllowed === null ? true : sendEmailAllowed == 'true' ? true : false}
                                />
                                <StyledSlider
                                  style={
                                    isDarkThemeEnabled
                                      ? {
                                          backgroundColor:
                                            sendEmailAllowed === null
                                              ? colorToUse?.PrimaryColorCode
                                              : sendEmailAllowed == 'true'
                                              ? colorToUse?.PrimaryColorCode
                                              : '#ccc',
                                        }
                                      : {}
                                  }
                                  className="slider round"
                                />
                              </Switch>
                            </div>
                          </Grid>
                          <Grid md={2}>
                            <div style={{ display: 'flex' }}>
                              <p className={classes.ButtonViewHide}>
                                {' '}
                                {isCustomBrandingColorsEnabled ? 'Enable' : 'Disable'}
                              </p>
                              <Switch className="switch" style={{ marginLeft: '6px' }}>
                                <StyledInput
                                  defaultChecked={isCustomBrandingColorsEnabled}
                                  value={isCustomBrandingColorsEnabled}
                                  type="checkbox"
                                  onChange={e => {
                                    switchFunCustomBranding(item, e);
                                  }}
                                  checked={isCustomBrandingColorsEnabled}
                                />
                                <StyledSlider
                                  style={
                                    isDarkThemeEnabled
                                      ? {
                                          backgroundColor: isCustomBrandingColorsEnabled
                                            ? colorToUse?.PrimaryColorCode
                                            : '#ccc',
                                        }
                                      : {}
                                  }
                                  className="slider round"
                                />
                              </Switch>
                            </div>
                          </Grid>
                        </StyledGrid>
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
              </div>
            </Grid>
          </Grid>
        </>
      )}

      {mobileView && (
        <>
          <Grid container>
            <Grid
              container
              style={{
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '30px',
                background: '#F5F5F5',
              }}
            >
              <div className={isDarkThemeEnabled ? classes.PannelTopBarTopDarkEN : classes.PannelTopBarTopEN}>
                Email Notifications
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '10px',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  marginBottom: '10px',
                  marginLeft: '10px',
                  flexWrap: 'wrap',
                }}
              >
                {loading === false ? (
                  sendingAddressState != 'noreply@cohere.live' &&
                  sendingAddressState != null && (
                    <div
                      style={{
                        backgroundColor: '#FFFFFF',
                        color: '#215C73',
                        border: '1px solid #215C73',
                        padding: '16px, 10px, 16px, 10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '40px',
                        borderRadius: '4px',
                        paddingInline: '10px',
                        fontWeight: '900',
                      }}
                    >
                      {sendingAddressState}
                      <span>
                        {' '}
                        <StyledCloseIcon
                          onClick={() => {
                            setSendingAddressState('noreply@cohere.live');
                            clearSendEmail();
                          }}
                          fontSize="8px"
                          style={{ color: 'black' }}
                        />
                      </span>
                    </div>
                  )
                ) : (
                  <Spinner color="#116582" size={20} />
                )}
                {activePaidTierPlan?.isEmailCampaignEnabled === false ||
                activePaidTierPlan?.isEmailCampaignEnabled === undefined ? (
                  <div
                    style={{
                      display: 'flex',
                      gap: '10px',
                      marginRight: '10px',
                      marginTop: '10px',
                      marginBottom: '10px',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Button
                      style={{
                        padding: '5px 20px',
                        opacity: 0.4,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      backgroundColor={colorToUse?.AccentColorCode}
                      textColor={colorToUse?.AccentColorCode}
                      borderColor={colorToUse?.AccentColorCode}
                      autoWidth
                      invert
                    >
                      <Tooltip
                        title="This feature is locked, please upgrade to Accelerate Plan."
                        arrow
                        enterTouchDelay={TOOLTIP.ENTER_DELAY}
                        leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                      >
                        <div
                          style={{
                            fontFamily: 'Avenir',
                            fontWeight: '400',
                            fontSize: '16px',
                            textDecoration: 'underline',
                            color: isDarkThemeEnabled ? '#9b9b9b' : '#2B2B2B66',
                          }}
                        >
                          Send From Email
                        </div>
                      </Tooltip>
                      <Tooltip
                        title="Use your personal email instead of noreply@cohere.live for notifications."
                        arrow
                        enterTouchDelay={TOOLTIP.ENTER_DELAY}
                        leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                      >
                        <InfoIcon
                          style={{ marginLeft: '10px', marginBottom: '3px' }}
                          htmlColor={colorToUse.PrimaryColorCode}
                        />
                      </Tooltip>
                    </Button>
                    <NewTag
                      onClick={() => {
                        setShowInterestFirstModal(true);
                      }}
                    >
                      Upgrade
                    </NewTag>
                  </div>
                ) : verifiedSendingAddresses.length > 0 ? (
                  <div
                    style={{
                      display: 'flex',
                      gap: '10px',
                      marginRight: '10px',
                      marginTop: '10px',
                      marginBottom: '10px',
                    }}
                  >
                    <Button
                      style={{ padding: '5px 10px' }}
                      onClick={() => {
                        setShowSendingEmailModal(true);
                      }}
                      backgroundColor={colorToUse?.AccentColorCode}
                      textColor={colorToUse?.AccentColorCode}
                      borderColor={colorToUse?.AccentColorCode}
                      autoWidth
                      invert
                    >
                      Send From Email
                      <Tooltip
                        title="Use your personal email instead of noreply@cohere.live for notifications."
                        arrow
                        enterTouchDelay={TOOLTIP.ENTER_DELAY}
                        leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                      >
                        <InfoIcon
                          style={{ marginLeft: '10px', marginBottom: '3px' }}
                          htmlColor={colorToUse.PrimaryColorCode}
                        />
                      </Tooltip>
                    </Button>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </Grid>
            <Grid item md={12} className={classes.MobileViewMain}>
              <div>
                <Accordion expanded={accordionOpen} id="panel1bh-header-container">
                  <AccordionSummary
                    style={{
                      backgroundColor: NotificationSettingBgColor,
                      padding: '0 10px',
                      width: '100%',
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    onClick={() => setAccordionOpen(!accordionOpen)}
                    className={isDarkThemeEnabled ? 'dark-notification-accordion' : ''}
                  >
                    <EnrolementSvg color={NotificationSettingTextColor} height="60%" width="10%" />
                    <Typography
                      sx={{
                        flexShrink: 0,
                        paddingLeft: '10px',
                        fontSize: '16px',
                        fontWeight: '850',
                        fontStyle: 'normal',
                        color: NotificationSettingTextColor,
                        fontFamily: 'Avenir',
                        width: '90%',
                      }}
                    >
                      Enrollment and Sales
                    </Typography>
                    <Typography sx={{ color: 'text.secondary' }}></Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.AccordianDetails}>
                    {EnrollmentSales.map((item, index) => {
                      const {
                        category,
                        emailSubject,
                        emailText,
                        emailType,
                        isEmailEnabled,
                        name,
                        sendEmailAllowed,
                        isCustomBrandingColorsEnabled,
                        currentColors,
                      } = item;
                      return (
                        <Grid container className={classes.MobileViewMainSalesCon}>
                          <Grid sm={6} md={6} style={{ width: '50%' }}>
                            <EmailHeading>Email Template</EmailHeading>
                            <p
                              onClick={() => {
                                click(item);
                              }}
                              style={{ maxWidth: '120px', cursor: 'pointer' }}
                            >
                              {name}
                            </p>
                          </Grid>
                          <Grid sm={6} md={6} style={{ width: '50%' }}>
                            <EmailHeading>Email Subject</EmailHeading>
                            <p style={{ maxWidth: '140px' }}>{emailSubject}</p>
                          </Grid>
                          <Grid sm={6} md={6} style={{ width: '50%' }}>
                            <EmailHeading style={{ display: 'flex' }}>
                              <div style={{ display: 'flex', width: 70 }}>Send This Email</div>
                              <Tooltip title={sentEmailText} arrow enterTouchDelay={TOOLTIP.ENTER_DELAY}>
                                <StyledInfoIcon
                                  htmlColor={contribution.brandingColors.PrimaryColorCode}
                                  style={{ marginRight: '10px' }}
                                />
                              </Tooltip>
                            </EmailHeading>
                            <div style={{ display: 'flex' }}>
                              <p className={classes.ButtonViewHide}>
                                {' '}
                                {sendEmailAllowed === null
                                  ? 'Enable'
                                  : sendEmailAllowed === 'true'
                                  ? 'Enable'
                                  : 'Disable'}
                              </p>
                              <Switch className="switch" style={{ marginLeft: '6px' }}>
                                <StyledInput
                                  defaultChecked={
                                    sendEmailAllowed === null ? true : sendEmailAllowed == 'true' ? true : false
                                  }
                                  value={sendEmailAllowed === null ? true : sendEmailAllowed == 'true' ? true : false}
                                  type="checkbox"
                                  onChange={e => {
                                    switchFun(item, e);
                                  }}
                                  checked={sendEmailAllowed === null ? true : sendEmailAllowed == 'true' ? true : false}
                                />
                                <StyledSlider
                                  style={
                                    isDarkThemeEnabled
                                      ? {
                                          backgroundColor:
                                            sendEmailAllowed === null
                                              ? colorToUse?.PrimaryColorCode
                                              : sendEmailAllowed == 'true'
                                              ? colorToUse?.PrimaryColorCode
                                              : '#ccc',
                                        }
                                      : {}
                                  }
                                  className="slider round"
                                />
                              </Switch>
                            </div>
                          </Grid>
                          <Grid sm={6} md={6} style={{ width: '50%' }}>
                            <EmailHeading style={{ display: 'flex' }}>
                              <div style={{ display: 'flex', width: 70 }}>Use My Branding</div>
                              <Tooltip title={useBrandingText} arrow enterTouchDelay={TOOLTIP.ENTER_DELAY}>
                                <StyledInfoIcon
                                  htmlColor={contribution.brandingColors.PrimaryColorCode}
                                  style={{ marginRight: '10px' }}
                                />
                              </Tooltip>
                            </EmailHeading>
                            <div style={{ display: 'flex' }}>
                              <p className={classes.ButtonViewHide}>
                                {' '}
                                {isCustomBrandingColorsEnabled ? 'Custom' : 'Default'}
                              </p>
                              <Switch className="switch" style={{ marginLeft: '6px' }}>
                                <StyledInput
                                  defaultChecked={isCustomBrandingColorsEnabled}
                                  value={isCustomBrandingColorsEnabled}
                                  type="checkbox"
                                  onChange={e => {
                                    switchFunCustomBranding(item, e);
                                  }}
                                  checked={isCustomBrandingColorsEnabled}
                                />
                                <StyledSlider
                                  style={
                                    isDarkThemeEnabled
                                      ? {
                                          backgroundColor: isCustomBrandingColorsEnabled
                                            ? colorToUse?.PrimaryColorCode
                                            : '#ccc',
                                        }
                                      : {}
                                  }
                                  className="slider round"
                                />
                              </Switch>
                            </div>
                          </Grid>
                          <Grid sm={6} md={6} style={{ width: '50%' }}>
                            <EmailHeading mobileView={mobileView}>Actions</EmailHeading>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <ViewButton PLeft />
                              <StyledEditButton
                                handleOnClick={() => {
                                  click(item);
                                }}
                                color={isDarkThemeEnabled ? colorToUse?.PrimaryColorCode : ''}
                              />{' '}
                            </div>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={accordionOpen1}>
                  <AccordionSummary
                    style={{
                      backgroundColor: NotificationSettingBgColor,
                      padding: '0 10px',
                      width: '100%',
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    onClick={() => setAccordionOpen1(!accordionOpen1)}
                    className={isDarkThemeEnabled ? 'dark-notification-accordion' : ''}
                  >
                    <SessionSvg color={NotificationSettingTextColor} height="60%" width="10%" />
                    <Typography
                      sx={{
                        flexShrink: 0,
                        paddingLeft: '10px',
                        fontSize: '16px',
                        fontWeight: '850',
                        fontStyle: 'normal',
                        color: NotificationSettingTextColor,
                        fontFamily: 'Avenir',
                        width: '90%',
                      }}
                    >
                      Session Reminders and Self-Paced Content
                    </Typography>
                    {/* <Typography sx={{ color: 'text.secondary' }}></Typography> */}
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: '30px 16px 16px' }}>
                    {SessionContent.map((item, index) => {
                      const {
                        category,
                        emailSubject,
                        emailText,
                        emailType,
                        isEmailEnabled,
                        sendEmailAllowed,
                        name,
                        isCustomBrandingColorsEnabled,
                        currentColors,
                      } = item;
                      return (
                        <Grid container className={classes.SessionContentMain}>
                          <Grid sm={6} md={6} style={{ width: '50%' }}>
                            <EmailHeading>Email Template</EmailHeading>
                            <p
                              onClick={() => {
                                click(item);
                              }}
                              style={{ maxWidth: '120px', cursor: 'pointer' }}
                            >
                              {name}
                            </p>
                          </Grid>
                          <Grid sm={6} md={6} style={{ width: '50%' }}>
                            <EmailHeading>Email Subject</EmailHeading>
                            <p style={{ maxWidth: '140px' }}>{emailSubject}</p>
                          </Grid>
                          <Grid sm={6} md={6} style={{ width: '50%' }}>
                            <EmailHeading style={{ display: 'flex' }}>
                              <div style={{ display: 'flex', width: 70 }}>Send This Email</div>
                              <Tooltip title={sentEmailText} arrow enterTouchDelay={TOOLTIP.ENTER_DELAY}>
                                <StyledInfoIcon
                                  htmlColor={contribution.brandingColors.PrimaryColorCode}
                                  style={{ marginRight: '10px' }}
                                />
                              </Tooltip>
                            </EmailHeading>
                            <div style={{ display: 'flex' }}>
                              <p className={classes.ButtonViewHide}>
                                {' '}
                                {sendEmailAllowed === null
                                  ? 'Enable'
                                  : sendEmailAllowed === 'true'
                                  ? 'Enable'
                                  : sendEmailAllowed === true
                                  ? 'Enable'
                                  : 'Disable'}
                              </p>
                              <Switch className="switch" style={{ marginLeft: '6px' }}>
                                <StyledInput
                                  defaultChecked={
                                    sendEmailAllowed === null ? true : sendEmailAllowed == 'true' ? true : false
                                  }
                                  value={sendEmailAllowed === null ? true : sendEmailAllowed == 'true' ? true : false}
                                  type="checkbox"
                                  onChange={e => {
                                    switchFun(item, e);
                                  }}
                                  checked={sendEmailAllowed === null ? true : sendEmailAllowed == 'true' ? true : false}
                                />
                                <StyledSlider
                                  style={
                                    isDarkThemeEnabled
                                      ? {
                                          backgroundColor:
                                            sendEmailAllowed === null
                                              ? 'Enable'
                                              : sendEmailAllowed === 'true'
                                              ? 'Enable'
                                              : sendEmailAllowed === true
                                              ? 'Enable'
                                              : 'Disable',
                                        }
                                      : {}
                                  }
                                  className="slider round"
                                />
                              </Switch>
                            </div>
                          </Grid>
                          <Grid sm={6} md={6} style={{ width: '50%' }}>
                            <EmailHeading style={{ display: 'flex' }}>
                              <div style={{ display: 'flex', width: 70 }}>Use My Branding</div>
                              <Tooltip title={useBrandingText} arrow enterTouchDelay={TOOLTIP.ENTER_DELAY}>
                                <StyledInfoIcon
                                  htmlColor={contribution.brandingColors.PrimaryColorCode}
                                  style={{ marginRight: '10px' }}
                                />
                              </Tooltip>
                            </EmailHeading>
                            <div style={{ display: 'flex' }}>
                              <p className={classes.ButtonViewHide}>
                                {' '}
                                {isCustomBrandingColorsEnabled ? 'Custom' : 'Default'}
                              </p>
                              <Switch className="switch" style={{ marginLeft: '6px' }}>
                                <StyledInput
                                  defaultChecked={isCustomBrandingColorsEnabled}
                                  value={isCustomBrandingColorsEnabled}
                                  type="checkbox"
                                  onChange={e => {
                                    switchFunCustomBranding(item, e);
                                  }}
                                  checked={isCustomBrandingColorsEnabled}
                                />
                                <StyledSlider
                                  style={
                                    isDarkThemeEnabled
                                      ? {
                                          backgroundColor: isCustomBrandingColorsEnabled
                                            ? colorToUse?.PrimaryColorCode
                                            : '#ccc',
                                        }
                                      : {}
                                  }
                                  className="slider round"
                                />
                              </Switch>
                            </div>
                          </Grid>
                          <Grid sm={6} md={6} style={{ width: '50%' }}>
                            <EmailHeading>Actions</EmailHeading>

                            <div className={classes.SessionContentBtn}>
                              <ViewButton PLeft />
                              <StyledEditButton
                                handleOnClick={() => {
                                  click(item);
                                }}
                                color={isDarkThemeEnabled ? colorToUse?.PrimaryColorCode : ''}
                              />{' '}
                            </div>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={accordionOpen2}>
                  <AccordionSummary
                    style={{
                      backgroundColor: NotificationSettingBgColor,
                      padding: '0 10px',
                      width: '100%',
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    onClick={() => setAccordionOpen2(!accordionOpen2)}
                    className={isDarkThemeEnabled ? 'dark-notification-accordion' : ''}
                  >
                    <CommunitySvg color={NotificationSettingTextColor} height="60%" width="10%" />
                    <Typography
                      sx={{
                        flexShrink: 0,
                        paddingLeft: '10px',
                        fontSize: '14px',
                        fontWeight: '850',
                        fontStyle: 'normal',
                        color: NotificationSettingTextColor,
                        fontFamily: 'Avenir',
                        width: '90%',
                      }}
                    >
                      Community, Posts and Comments
                    </Typography>
                    <Typography sx={{ color: 'text.secondary' }}></Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: '30px 16px 16px' }}>
                    {Community.map((item, index) => {
                      const {
                        category,
                        emailSubject,
                        emailText,
                        emailType,
                        isEmailEnabled,
                        sendEmailAllowed,
                        name,
                        isCustomBrandingColorsEnabled,
                        currentColors,
                      } = item;
                      return (
                        <Grid container className={classes.MobileViewCommunity}>
                          <Grid sm={6} md={6} style={{ width: '50%' }}>
                            <EmailHeading>Email Template</EmailHeading>
                            <p
                              onClick={() => {
                                click(item);
                              }}
                              style={{ maxWidth: '120px', cursor: 'pointer' }}
                            >
                              {name}
                            </p>
                          </Grid>
                          <Grid sm={6} md={6} style={{ width: '50%' }}>
                            <EmailHeading>Email Subject</EmailHeading>
                            <p style={{ maxWidth: '140px' }}>{emailSubject}</p>
                          </Grid>
                          <Grid sm={6} md={6} style={{ width: '50%' }}>
                            <EmailHeading style={{ display: 'flex' }}>
                              <div style={{ display: 'flex', width: 70 }}>Send This Email</div>
                              <Tooltip title={sentEmailText} arrow enterTouchDelay={TOOLTIP.ENTER_DELAY}>
                                <StyledInfoIcon
                                  htmlColor={contribution.brandingColors.PrimaryColorCode}
                                  style={{ marginRight: '10px' }}
                                />
                              </Tooltip>
                            </EmailHeading>
                            <div style={{ display: 'flex' }}>
                              <p className={classes.ButtonViewHide}>
                                {' '}
                                {sendEmailAllowed === null
                                  ? 'Enable'
                                  : sendEmailAllowed === 'true'
                                  ? 'Enable'
                                  : 'Disable'}
                              </p>
                              <Switch className="switch" style={{ marginLeft: '6px' }}>
                                <StyledInput
                                  defaultChecked={
                                    sendEmailAllowed === null ? true : sendEmailAllowed == 'true' ? true : false
                                  }
                                  value={sendEmailAllowed === null ? true : sendEmailAllowed == 'true' ? true : false}
                                  type="checkbox"
                                  onChange={e => {
                                    switchFun(item, e);
                                  }}
                                  checked={sendEmailAllowed === null ? true : sendEmailAllowed == 'true' ? true : false}
                                />
                                <StyledSlider
                                  style={
                                    isDarkThemeEnabled
                                      ? {
                                          backgroundColor:
                                            sendEmailAllowed === null
                                              ? colorToUse?.PrimaryColorCode
                                              : sendEmailAllowed == 'true'
                                              ? colorToUse?.PrimaryColorCode
                                              : '#ccc',
                                        }
                                      : {}
                                  }
                                  className="slider round"
                                />
                              </Switch>
                            </div>
                          </Grid>
                          <Grid sm={6} md={6} style={{ width: '50%' }}>
                            <EmailHeading style={{ display: 'flex' }}>
                              <div style={{ display: 'flex', width: 70 }}>Use My Branding</div>{' '}
                              <Tooltip title={useBrandingText} arrow enterTouchDelay={TOOLTIP.ENTER_DELAY}>
                                <StyledInfoIcon
                                  htmlColor={contribution.brandingColors.PrimaryColorCode}
                                  style={{ marginRight: '10px' }}
                                />
                              </Tooltip>
                            </EmailHeading>
                            <div style={{ display: 'flex' }}>
                              <p className={classes.ButtonViewHide}>
                                {' '}
                                {isCustomBrandingColorsEnabled ? 'Custom' : 'Default'}
                              </p>
                              <Switch className="switch" style={{ marginLeft: '6px' }}>
                                <StyledInput
                                  defaultChecked={isCustomBrandingColorsEnabled}
                                  value={isCustomBrandingColorsEnabled}
                                  type="checkbox"
                                  onChange={e => {
                                    switchFunCustomBranding(item, e);
                                  }}
                                  checked={isCustomBrandingColorsEnabled}
                                />
                                <StyledSlider
                                  style={
                                    isDarkThemeEnabled
                                      ? {
                                          backgroundColor: isCustomBrandingColorsEnabled
                                            ? colorToUse?.PrimaryColorCode
                                            : '#ccc',
                                        }
                                      : {}
                                  }
                                  className="slider round"
                                />
                              </Switch>
                            </div>
                          </Grid>
                          <Grid sm={6} md={6} style={{ width: '50%' }}>
                            <EmailHeading style={{}}>Actions</EmailHeading>

                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <ViewButton PLeft />
                              <StyledEditButton
                                handleOnClick={() => {
                                  click(item);
                                }}
                                color={isDarkThemeEnabled ? colorToUse?.PrimaryColorCode : ''}
                              />{' '}
                            </div>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={accordionOpen4}>
                  <AccordionSummary
                    style={{
                      backgroundColor: NotificationSettingBgColor,
                      padding: '0 10px',
                      width: '100%',
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    onClick={() => setAccordionOpen4(!accordionOpen4)}
                    className={isDarkThemeEnabled ? 'dark-notification-accordion' : ''}
                  >
                    <EnrolementSvg color={NotificationSettingTextColor} height="60%" width="10%" />
                    <Typography
                      sx={{
                        flexShrink: 0,
                        paddingLeft: '10px',
                        fontSize: '16px',
                        fontWeight: '850',
                        fontStyle: 'normal',
                        color: NotificationSettingTextColor,
                        fontFamily: 'Avenir',
                        width: '90%',
                      }}
                    >
                      My Email Notifications
                    </Typography>
                    <Typography sx={{ color: 'text.secondary' }}></Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.AccordianDetails}>
                    {EmailNotification.map((item, index) => {
                      const {
                        category,
                        emailSubject,
                        emailText,
                        emailType,
                        isEmailEnabled,
                        sendEmailAllowed,
                        name,
                        isCustomBrandingColorsEnabled,
                        currentColors,
                      } = item;
                      return (
                        <Grid container className={classes.MobileViewMainSalesCon}>
                          <Grid sm={6} md={6} style={{ width: '50%' }}>
                            <EmailHeading>Email Template</EmailHeading>
                            <p
                              onClick={() => {
                                click(item);
                              }}
                              style={{ maxWidth: '120px', cursor: 'pointer' }}
                            >
                              {name}
                            </p>
                          </Grid>
                          <Grid sm={6} md={6} style={{ width: '50%' }}>
                            <EmailHeading>Email Subject</EmailHeading>
                            <p style={{ maxWidth: '140px' }}>{emailSubject}</p>
                          </Grid>
                          <Grid sm={6} md={6} style={{ width: '50%' }}>
                            <EmailHeading style={{ display: 'flex' }}>
                              <div style={{ display: 'flex', width: 70 }}>Send This Email</div>
                              <Tooltip title={sentEmailText} arrow enterTouchDelay={TOOLTIP.ENTER_DELAY}>
                                <StyledInfoIcon
                                  htmlColor={contribution.brandingColors.PrimaryColorCode}
                                  style={{ marginRight: '10px' }}
                                />
                              </Tooltip>
                            </EmailHeading>
                            <div style={{ display: 'flex' }}>
                              <p className={classes.ButtonViewHide}>
                                {' '}
                                {sendEmailAllowed === null
                                  ? 'Enable'
                                  : sendEmailAllowed === 'true'
                                  ? 'Enable'
                                  : 'Disable'}
                              </p>
                              <Switch className="switch" style={{ marginLeft: '6px' }}>
                                <StyledInput
                                  defaultChecked={
                                    sendEmailAllowed === null ? true : sendEmailAllowed == 'true' ? true : false
                                  }
                                  value={sendEmailAllowed === null ? true : sendEmailAllowed == 'true' ? true : false}
                                  type="checkbox"
                                  onChange={e => {
                                    switchFun(item, e);
                                  }}
                                  checked={sendEmailAllowed === null ? true : sendEmailAllowed == 'true' ? true : false}
                                />
                                <StyledSlider
                                  style={
                                    isDarkThemeEnabled
                                      ? {
                                          backgroundColor:
                                            sendEmailAllowed === null
                                              ? colorToUse?.PrimaryColorCode
                                              : sendEmailAllowed == 'true'
                                              ? colorToUse?.PrimaryColorCode
                                              : '#ccc',
                                        }
                                      : {}
                                  }
                                  className="slider round"
                                />
                              </Switch>
                            </div>
                          </Grid>
                          <Grid sm={6} md={6} style={{ width: '50%' }}>
                            <EmailHeading style={{ display: 'flex' }}>
                              <div style={{ display: 'flex', width: 70 }}>Use My Branding</div>
                              <Tooltip title={useBrandingText} arrow enterTouchDelay={TOOLTIP.ENTER_DELAY}>
                                <StyledInfoIcon
                                  htmlColor={contribution.brandingColors.PrimaryColorCode}
                                  style={{ marginRight: '10px' }}
                                />
                              </Tooltip>
                            </EmailHeading>
                            <div style={{ display: 'flex' }}>
                              <p className={classes.ButtonViewHide}>
                                {' '}
                                {isCustomBrandingColorsEnabled ? 'Custom' : 'Default'}
                              </p>
                              <Switch className="switch" style={{ marginLeft: '6px' }}>
                                <StyledInput
                                  defaultChecked={isCustomBrandingColorsEnabled}
                                  value={isCustomBrandingColorsEnabled}
                                  type="checkbox"
                                  onChange={e => {
                                    switchFunCustomBranding(item, e);
                                  }}
                                  checked={isCustomBrandingColorsEnabled}
                                />
                                <StyledSlider
                                  style={
                                    isDarkThemeEnabled
                                      ? {
                                          backgroundColor: isCustomBrandingColorsEnabled
                                            ? colorToUse?.PrimaryColorCode
                                            : '#ccc',
                                        }
                                      : {}
                                  }
                                  className="slider round"
                                />
                              </Switch>
                            </div>
                          </Grid>
                          <Grid sm={6} md={6} style={{ width: '50%' }}>
                            <EmailHeading mobileView={mobileView}>Actions</EmailHeading>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <ViewButton PLeft />
                              <StyledEditButton
                                handleOnClick={() => {
                                  click(item);
                                }}
                                color={isDarkThemeEnabled ? colorToUse?.PrimaryColorCode : ''}
                              />{' '}
                            </div>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
              </div>
            </Grid>
            {loader && <Loader />}
          </Grid>
        </>
      )}

      {automationsloader && <Loader />}
      <Modal
        isOpen={isCoach && emailAutomations}
        title="Customize Email Automations?"
        submitTitle="Leave Enabled & Customize Now"
        cancelTitle="Disable Emails & Customize Later"
        onSubmit={onEmailAutomationsSubmit}
        onCancel={onEmailAutomationsCancel}
        closeClick={true}
        disableOverlayClick
        onCloseClick={() => {
          setEmailAutomations(false);
          dispatch(setIsNewlyCreated(false));
        }}
        className="delete-application-form-modal"
      >
        <p style={{ color: isDarkThemeEnabled ? 'white' : '' }}>
          Would you like to personalize the automated emails your clients will receive for {contribution?.title}? By
          default, these emails are already enabled. What would you like to do next?
        </p>
      </Modal>
      <FeatureFeedbackModal
        isOpen={isFeedbackPopupOpen}
        handleClose={handleCloseFeedback}
        isDarkModeEnabled={contribution?.isDarkModeEnabled}
        feature="Service"
        mobileView={mobileView}
        primaryColorCode={colorToUse?.PrimaryColorCode}
        handleFinishFeedback={handleFinishFeedbackCallback}
      />
      <Joyride
        callback={handleJoyrideCallback}
        continuous={true}
        getHelpers={getHelpers}
        run={run}
        stepIndex={stepIndex}
        showProgress={false}
        showSkipButton={false}
        steps={steps}
        styles={{
          options: {
            primaryColor: '#CDBA8F',
            zIndex: 10000000,
            backgroundColor: isDarkThemeEnabled ? '#2d2f31' : 'rgba(255, 255, 255)',
          },
        }}
      />

      <Modal
        isOpen={showSendingEmailModal}
        title={`Choose Email Address To Send Automated Notifications     `}
        submitTitle="Done"
        cancelTitle="Cancel"
        onSubmit={() => {
          setShowSendingEmailModal(false);
          axiosInstance
            .post(`/Contribution/UpdateNotificationSendFrom/${contributionId}/${sendingAddressState}`)
            .then(() => {
              dispatch(fetchCohealerContribution(contributionId));
            });
        }}
        onCancel={() => {
          setShowSendingEmailModal(false);
          setSendingAddressState('noreply@cohere.live');
        }}
        closeClick={true}
        disableOverlayClick
        shrinkCancelButton={true}
        onCloseClick={() => {
          setShowSendingEmailModal(false);
          setSendingAddressState('noreply@cohere.live');
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
          <Select
            style={{ overflow: 'hidden' }}
            className="select-field"
            variant="outlined"
            fullWidth
            value={sendingAddressState || 'noreply@cohere.live'}
            input={<OutlinedInput placeholder="Choose a Prior Form" margin="dense" />}
            onChange={e => {
              setSendingAddressState(e.target.value);
            }}
            renderValue={value => (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div>{`${value}`}</div>{' '}
                {isSelectedEmailDefault ? (
                  <span
                    style={{
                      height: '20px',
                      width: '50px',
                      color: 'white',
                      borderRadius: '4px',
                      background: '#C9B382',
                      fontFamily: 'Avenir',
                      fontWeight: '800',
                      fontSize: '11px',
                      marginLeft: '10px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                    }}
                  >
                    Default
                  </span>
                ) : (
                  <></>
                )}
              </div>
            )}
            MenuProps={{
              PaperProps: {
                style: {
                  marginTop: 0, // Adjusts the margin between the Select field and dropdown menu
                  maxHeight: '300px',
                },
              },
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null, // Ensures the dropdown aligns with the bottom of the Select field
            }}
          >
            {verifiedSendingAddresses != undefined &&
              verifiedSendingAddresses.length > 0 &&
              [{ email: 'noreply@cohere.live', isDefault: false }].concat(verifiedSendingAddresses)?.map(data => {
                return (
                  <MenuItem value={data?.email} key={data?.email} maxWidth={'400px'}>
                    <div>{`${data?.email}`}</div>{' '}
                    {data.isDefault === true ? (
                      <span
                        style={{
                          height: '20px',
                          width: '50px',
                          color: 'white',
                          borderRadius: '4px',
                          background: '#C9B382',
                          fontFamily: 'Avenir',
                          fontWeight: '800',
                          fontSize: '11px',
                          marginLeft: '10px',
                          justifyContent: 'center',
                          alignItems: 'center',
                          display: 'flex',
                        }}
                      >
                        Default
                      </span>
                    ) : (
                      <></>
                    )}
                  </MenuItem>
                );
              })}
          </Select>
        </div>
      </Modal>
      <Modal
        isOpen={showInterestModal}
        minHeight={'115px'}
        minWidth={'800px'}
        title="Thank you for your interest in the Accelerate Plan!"
        onSubmit={() => {
          setShowInterestModal(false);
          setShowInterestFirstModal(false);
        }}
        onCancel={() => {
          setShowInterestFirstModal(false);
          setShowInterestModal(false);
        }}
        disableFooter
      >
        <span className={classes.text}>We will be reaching out to you shortly for the next steps.</span>
      </Modal>
      <Modal
        isOpen={showInterestFirstModal}
        minWidth={'800px'}
        title="Upgarde To Accelerate Plan"
        onSubmit={() => {
          setShowInterestModal(true);
          notifyPlanUpgrade();
        }}
        onCancel={() => {
          setShowInterestFirstModal(false);
        }}
        submitTitle={'Upgrade'}
        hiddenCancel={true}
      >
        <span className={classes.text}>
          This feature is exclusive to Accelerate Plans, if you are interested in upgrading, click the below button.
        </span>
      </Modal>
    </>
  );
}
